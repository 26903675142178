import axios from 'axios'
export default {
  namespaced: true,
  state() {
    return {
      user: {},
      accessToken: localStorage.getItem('auth-token') || '',
      refreshToken: localStorage.getItem('refresh-token') || '',
      loggedIn: false
    }
  },
  mutations: {
    setToken(state, token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`
      state.accessToken = token.access_token
      state.refreshToken = token.refresh_token
      state.loggedIn = true
    },
    setAuthUser(state, user) {
      state.user = user
    },
    logout(state) {
      axios.defaults.headers.common.Authorization = ''
      state.loggedIn = false
      state.accessToken = ''
      state.refreshToken = ''
      state.user = {}
      localStorage.setItem('auth-token', '')
      localStorage.setItem('refresh-token', '')
    }
  },
  actions: {
    logout({ commit }) {
      commit('logout')
    },
    getAuthUser({ commit }) {
      axios.get('/api/me').then((res) => {
        commit('setAuthUser', res.data.data)
      })
    },
    setToken({ commit, dispatch }, token) {
      commit('setToken', token)
      dispatch('getAuthUser')
    },
    async login({ dispatch }, user) {
      await axios
        .post('/oauth/token', {
          username: user.username,
          password: user.password,
          grant_type: 'password',
          client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
          client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
          scope: '*'
        })
        .then((res) => {
          const token = res.data
          localStorage.setItem('auth-token', token.access_token)
          localStorage.setItem('refresh-token', token.refresh_token)
          dispatch('setToken', token)
        })
    }
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn
    },
    authUser(state) {
      return state.user
    }
  }
}

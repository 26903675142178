import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
Vue.use(Router)
const RouterLayout = createRouterLayout((layout) => {
  return import('@/layouts/' + layout + '.vue')
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes
    }
  ]
})
router.beforeEach((to, from, next) => {
  if (
    to.meta.requiresAuth === false ||
    router.app.$store.getters['auth/loggedIn']
  ) {
    if (to.meta.requiresAdmin !== undefined) {
      const authUser = router.app.$store.getters['auth/authUser']
      if (authUser.is_admin) {
        next()
      } else {
        return false
      }
    } else {
      next()
    }
  } else {
    next({ name: 'login' })
  }
})
export default router

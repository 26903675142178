export default {
  namespaced: true,
  state() {
    return {
      showLoader: false,
      toastVariant: 'default',
      toastMessage: '',
      showToast: false,
      markets: [],
      countries: [],
      platforms: [],
      cities: []
    }
  },
  mutations: {
    hideToast(state) {
      state.showToast = false
    },
    showToast(state) {
      state.showToast = true
    },
    setLoaderVisibility(state, value) {
      state.showLoader = value
    },
    setToastVariant(state, value) {
      state.toastVariant = value
    },
    setToastMessage(state, value) {
      state.toastMessage = value
    },
    setPlatformOptions(state, value) {
      state.platforms = value
    },
    setMarketOptions(state, value) {
      state.markets = value
    },
    setCountryOptions(state, value) {
      state.countries = value
    },
    setCitiesOptions(state, value) {
      state.cities = value
    }
  },
  actions: {
    hideToast({ commit }) {
      commit('setToastVariant', null)
      commit('setToastMessage', '')
      commit('hideToast')
    },
    setLoaderVisibility({ commit }, value) {
      commit('setLoaderVisibility', value)
    },
    showToast({ commit }, { message, variant }) {
      commit('setToastVariant', variant)
      commit('setToastMessage', message)
      commit('showToast')
    },
    setMarketOptions({ commit }, value) {
      commit('setMarketOptions', value)
    },
    setCountryOptions({ commit }, value) {
      commit('setCountryOptions', value)
    },
    setPlatformOptions({ commit }, value) {
      commit('setPlatformOptions', value)
    },
    setCitiesOptions({ commit }, value) {
      commit('setCitiesOptions', value)
    }
  },
  getters: {
    showLoader(state) {
      return state.showLoader
    },
    showToast(state) {
      return state.showToast
    },
    toastVariant(state) {
      return state.toastVariant
    },
    toastMessage(state) {
      return state.toastMessage
    },
    markets(state) {
      return state.markets
    },
    countries(state) {
      return state.countries
    },
    platforms(state) {
      return state.platforms
    },
    cities(state) {
      return [{ value: null, text: '' }].concat(state.cities)
    }
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import authModule from '../store/auth'
import layout from '../store/layout'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    layout
  }
})

export default store

/* eslint-env node */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import router from './router'
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2'
import VuePageTransition from 'vue-page-transition'
import Echo from 'laravel-echo'
import VueEcho from 'vue-echo-laravel'
window.Pusher = require('pusher-js')
axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VuePageTransition)
Vue.use(VueCookies)
Vue.use(VueSweetalert2)
axios.defaults.headers.common.Accept = 'application/json'
axios.interceptors.request.use(
  (config) => {
    if (
      !(
        typeof config.headers['Hide-Loader'] !== 'undefined' &&
        config.headers['Hide-Loader'] === true
      )
    ) {
      store.dispatch('layout/setLoaderVisibility', true)
    }
    return config
  },
  (error) => {
    store.dispatch('layout/setLoaderVisibility', false)
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    store.dispatch('layout/setLoaderVisibility', false)
    return response
  },
  (error) => {
    store.dispatch('layout/setLoaderVisibility', false)
    return Promise.reject(error)
  }
)
const authToken = localStorage.getItem('auth-token')
if (authToken) {
  const refreshToken = localStorage.getItem('refresh-token')
  store.dispatch('auth/setToken', {
    access_token: authToken,
    refresh_token: refreshToken
  })
}
axios.get('/api/cluster/markets').then((res) => {
  const data = res.data
  store.dispatch('layout/setMarketOptions', data)
})

axios.get('/api/cluster/countries').then((res) => {
  const data = res.data
  store.dispatch('layout/setCountryOptions', data)
})
axios.get('/api/cluster/platforms').then((res) => {
  const data = res.data
  store.dispatch('layout/setPlatformOptions', data)
})
axios.get('/api/cluster/cities').then((res) => {
  const data = res.data
  store.dispatch('layout/setCitiesOptions', data)
})
const echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WS_APP_KEY,
  wsHost: process.env.VUE_APP_WS_HOST,
  wsPort: process.env.VUE_APP_WS_PORT,
  wssPort: process.env.VUE_APP_WSS_POR,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  }
})
Vue.use(VueEcho, echo)
export default new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
